<template>
  <div>
    <div class="login mt-28">
      <a-button
        :style="{
          border: 'none',
          background: 'none',
          color: '#000',
          boxShadow: 'none',
          padding: '0px'
        }"
        class="my-6"
        @click="$router.push({ name: 'Login' })"
      >
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        Login</a-button
      >
      <p class="my-4 text-xl font-medium">Verify Your account</p>
      <a-form
        layout="vertical"
        :model="credentials"
        ref="forgotRef"
        :rules="rules"
        class="my-8"
      >
        <a-form-item label="Verification Code:" name="code">
          <a-input v-model:value="credentials.code" placeholder="797fb1" />
        </a-form-item>
        <a-form-item>
          <AppButton
            buttonText="Send"
            @buttonAction="handleVerify"
            :loading="requestLoading"
            buttonClass="w-full"
          />
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import useLoadingState from '@/use/loading-state'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'

export default {
  components: { ArrowLeftOutlined },
  setup() {
    const { requestLoading } = useLoadingState()
    const store = useStore()
    const credentials = ref({ code: '' })
    const forgotRef = ref()
    const rules = {
      email: [
        {
          required: true,
          message: 'Please enter your verification code',
          trigger: 'blur'
        }
      ]
    }
    const handleVerify = () => {
      const payload = {
        verificationCode: credentials.value.code
      }
      store.dispatch('auth/VerifyUserMail', payload)
    }
    return { credentials, rules, forgotRef, requestLoading, handleVerify }
  }
}
</script>

<style lang="scss" scoped></style>
